import React, {useState} from 'react';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import SEO from '../components/SEO/SEO';
import website from '../utils/site-config';
import Component3D from '../components/CaseTemplates/component3D/Component3D';
import CaseFooter from '../components/CaseTemplates/CaseFooter';
//import Banner3D from '../components/CaseTemplates/Banner3D';
//import Testimonial from '../components/CaseTemplates/Testimonial';
import VideoBanner from '../components/CaseTemplates/VideoBanner';
//import PanoCarousel360 from '../components/CaseTemplates/PanoCarousel360';
import '../styles/utils/general.scss';
import {isTouchDevice} from '../utils/helpers';

const TemplateT = ({
  pageContext: {
    locale,
    folderName,
    path,
    casesArrayForPage,
    previousPathForPage,
  },
  data,
  location,
}) => {
  const [scrollInteraction, setScrollInteraction] = useState (false);

  const fileFrontmatter = data.file.childMarkdownRemark.frontmatter;

  var previousPath = previousPathForPage;
  var currentCasesArray = casesArrayForPage;

  location.state
    ? (previousPath = location.state.previousPath)
    : (previousPath = previousPathForPage);
  location.state
    ? (currentCasesArray = location.state.currentCasesArray)
    : (currentCasesArray = casesArrayForPage);

  return (
    <Layout path={fileFrontmatter.path} locale={locale}>
      <SEO
        title={`${fileFrontmatter.title} | ${website._title}`}
        pathname={fileFrontmatter.path}
        desc={fileFrontmatter.description}
        banner={fileFrontmatter.image.publicURL}
        showCase
      />
      <div
        className="static-banner-container"
        onWheel={() => setScrollInteraction (true)}
        onTouchStart={
          typeof window !== `undefined` && isTouchDevice ()
            ? () => setScrollInteraction (true)
            : undefined
        }
      >
        <VideoBanner
          videoBannerId={fileFrontmatter.videoBannerId.publicURL}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
          scrollInteraction={scrollInteraction}
          scrollInteractionSet={setScrollInteraction}
        />
      </div>
      <div className="component3d-container">
        <Component3D
          paddingTop={'130px'}
          project3D={fileFrontmatter.project3D}
          externalLink3DComponent={fileFrontmatter.externalLink3DComponent}
          messageFor3DComponent={fileFrontmatter.messageFor3DComponent}
        />
      </div>
      <div className="case-footer">
        <CaseFooter
          locale={locale}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          solutionTag={fileFrontmatter.solutionTag}
          path={fileFrontmatter.path}
          title={fileFrontmatter.title}
          previousPath={previousPath}
          currentCasesArray={currentCasesArray}
          previousPathForPage={previousPathForPage}
        />
      </div>
      <div>{fileFrontmatter.title}</div>
      <div>{fileFrontmatter.template}</div>
      <span className="material-icons">check_circle</span>
    </Layout>
  );
};

export default TemplateT;

export const query = graphql`
  query TemplateT($locale: String, $folderName: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: $folderName }) {
      childMarkdownRemark {
        frontmatter {
          title
          segmentTag
          areaTag
          solutionTag
          tags
          productTypeShort
          productType
          videoBannerId{
              publicURL            
          }
          messageFor360Gallery
          externalLink360Gallery
          path
          description
          panoCarouselFolder
          panoCarousel360{
            panoImage
            hlookat
            vlookat
            fov
          }
          template
          banner3D{
            folderName
            specsSmallScreen{
              fov
              minDistance
            }
            specsDesktop{
              fov
              minDistance
            }
          }
          project3D{
            folderName
            specsSmallScreen{
              fov
              minDistance
              enableWireframe
              initialPosition{
                x
                y
                z
              }
              initialTarget{
                x
                y
                z
              }
              polarAngleRange
            }
            specsDesktop{
              fov
              minDistance
              enableWireframe
              initialPosition{
                x
                y
                z
              }
              initialTarget{
                x
                y
                z
              }
              polarAngleRange
            }
          }
          backgroundImage3D
          externalLink3DComponent
          messageFor3DComponent
          caseContentRecommendation
          caseContentRecommendationName
          caseContentRecommendationRole
          panoCarousel360{
            panoImage
            hlookat
            vlookat
            fov
          }
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1036) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

/*

      <div
        className="case--banner-wrapper"
        onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
      >
        <Banner3D
          banner3D={fileFrontmatter.banner3D}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
          scrollInteraction={scrollInteraction}
        />
      </div>









     <div
        className="static-banner-container"
        onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
      >
        <VideoBanner
          videoBannerId={fileFrontmatter.videoBannerId}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
          scrollInteraction={scrollInteraction}
        />
      </div>



   <div className="component3d-container">
        <Component3D
          externalLink3DComponent={fileFrontmatter.externalLink3DComponent}
          messageFor3DComponent={fileFrontmatter.messageFor3DComponent}
          project3D={fileFrontmatter.project3D}
          backgroundImage3D={fileFrontmatter.backgroundImage3D}
        />
      </div>


   <div
          className="case--banner-wrapper"
          onWheel={() => setScrollInteraction (true)}
            onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
        >
          <Banner3D
            banner3D={fileFrontmatter.banner3D}
            title={fileFrontmatter.title}
            productTypeShort={fileFrontmatter.productTypeShort}
            segmentTag={fileFrontmatter.segmentTag}
            areaTag={fileFrontmatter.areaTag}
            solutionTag={fileFrontmatter.solutionTag}
            productType={fileFrontmatter.productType}
                       scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
          />
        </div>




      <PanoCarousel360
  panos={fileFrontmatter.panoCarousel360}
  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
  externalLink360Gallery={fileFrontmatter.externalLink360Gallery}
  panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
/>;




      <div
        className="static-banner-container"
        onWheel={() => setScrollInteraction (true)}
      >
        <VideoBanner
          videoBannerId={fileFrontmatter.videoBannerId}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
                     scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
        />
      </div>
      <Testimonial
        text={fileFrontmatter.caseContentRecommendation}
        name={fileFrontmatter.caseContentRecommendationName}
        role={fileFrontmatter.caseContentRecommendationRole}
      />
      <PanoCarousel360
        panos={fileFrontmatter.panoCarousel360}
        messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
        externalLink360Gallery={fileFrontmatter.externalLink360Gallery}
        panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
      />
*/
